.navigation {
	position:fixed;
	width: 100%;
	text-align: center;
	height:2rem;
	background: white;
	border-bottom: 1px solid #606060;
	a {
		line-height: 2rem;
		text-transform: uppercase;
		color: #37b9da;
		&:first-child {
			margin-right: 2rem;
		}
		&.absolute-anchor {
			position: absolute;
			right: 1rem;
			text-transform: none;
		}
	}
}
.iframe-wrapper {
	width: 100vw;
	height: 100vh;
	padding-top: 32px;
}